<template>
  <div class="content-wrapper view-aptlist-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-list"></i> แพ็กเกจจัดฟัน</h4>
          <div class="row mx-1 my-3">
            <b-form-input
              type="search"
              class="col"
              autofocus
              v-model="filter"
              placeholder="ค้นหาแพ็กเกจจัดฟัน"
            ></b-form-input>
            <b-button
              v-if="isCounterOrManager"
              class="col-5 col-sm-4 col-md-3 ml-3"
              variant="primary"
              @click="openPackageItemModal()"
            >
              <i class="fas fa-plus"></i> เพิ่มแพ็กเกจ
            </b-button>
          </div>
          <b-table
            id="packageManageTable"
            ref="packageManageTable"
            hover
            small
            sort-icon-left
            head-variant="light"
            :items="getOrthoPackageList"
            :fields="fields"
            :filter="filter"
            sort-by="creationDt"
            :sort-desc="true"
            :responsive="true"
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
            <template v-slot:cell(creationDt)="row">{{
              formatDate(row.item.creationDt)
            }}</template>
            <template v-slot:cell(buttons)="row">
              <b-button-group class="show-when-hovered" size="xs">
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-secondary"
                  @click="openEditPackageItemModal(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-else
                  variant="outline-secondary"
                  @click="showPackageDetail(row.item)"
                >
                  <i class="fas fa-edit"></i>
                </b-button>
                <b-button
                  v-if="isCounterOrManager"
                  variant="outline-danger"
                  @click="deleteAptItem(row.item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </div>
      <PackageItem ref="packageItemModal"></PackageItem>
      <PackageUserModal ref="PackageUserModal"></PackageUserModal>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import PackageItem from "@/components/modal/PackageItem";
import PackageUserModal from "@/components/modal/PackageUser";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
  name: "PackageManage",
  components: {
    PackageItem,
    PackageUserModal,
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "nameTh",
          label: "ชื่อภาษาไทย",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "ชื่อภาษาอังกฤษ",
          sortable: true,
        },
        {
          key: "totalPrice",
          label: "ราคารวม",
        },
        {
          key: "creationDt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      filter: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getOrthoPackageList: "moduleTreatment/getOrthoPackageList",
      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),
    isCounterOrManager() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchOrthoPackageList: "moduleTreatment/fetchOrthoPackageList",
    }),
    init() {},
    moment() {
      return moment();
    },
    openPackageItemModal() {
      this.$refs.packageItemModal.show();
    },
    openEditPackageItemModal(packages) {
      this.isLoading = true;
      this.$store
        .dispatch("moduleTreatment/getOrthoPackageById", {
          event: packages.id,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then((res) => {
          this.$refs.packageItemModal.show(res.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showPackageDetail(pkg) {
      this.isLoading = true;
      this.$store
        .dispatch("moduleTreatment/getOrthoPackageById", {
          event: pkg.id,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then((res) => {
          let selectedPackage = res.data;
          let df =
            selectedPackage.dfDoctors.find(
              (doctor) => doctor.doctorUid === this.userInfo.uid
            )?.df ?? selectedPackage.df;
          this.$refs.PackageUserModal.showSelectedPackage(selectedPackage, df);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteAptItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบแพ็กเกจจัดฟัน ?",
        "คุณต้องการลบแพ็กเกจจัดฟันนี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleTreatment/deleteOrthoPackage", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.fetchOrthoPackageList({
                  clinicUrl: this.$route.params.clinicUrl,
                  branchUrl: this.$route.params.branchUrl,
                });
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
  },
  updated() {},
  destroyed() {
    this.$store.dispatch("moduleTreatment/fetchOrthoPackageList", {
      clinicUrl: this.$route.params.clinicUrl,
      branchUrl: this.$route.params.branchUrl,
    });
  },
};
</script>

<style scoped>
</style>
