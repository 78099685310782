<template>
  <b-modal
    modal-class="component-modal-package"
    id="packageModal"
    ref="packageModal"
    size="lg"
    :title="modalTitle"
    no-close-on-backdrop
    :hide-footer="!templatePackage"
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <div v-if="isNewPackage">
      <b-form-group
        label-cols="3"
        label="เพิ่มแพ็กเกจใหม่โดย:"
        label-align="right"
      >
        <multiselect
          class="col p-0"
          v-model="templatePackage"
          :options="getGroupedTemplateOrthoPackageList"
          :multiple="false"
          group-values="libs"
          group-label="category"
          :group-select="false"
          placeholder="สร้างแพ็กเกจใหม่ทั้งหมด หรือลอกแบบจากแพ็กเกจเดิม"
          track-by="id"
          :custom-label="customLabel"
          selectLabel="เลือกแพ็กเกจ"
          selectedLabel="เลือกแล้ว"
          deselectLabel="คลิกเพื่อลบออก"
          openDirection="below"
        >
          <span slot="noResult">ไม่พบรายการ แนะนำเปลี่ยนคำค้นหา</span>
        </multiselect>
      </b-form-group>
      <hr />
    </div>
    <div v-if="templatePackage">
      <b-form id="packageTable" @submit.prevent="savePackage">
        <b-form-group label-cols="3" label="ชื่อแพ็กเกจ:" label-align="right">
          <b-form-input
            class="required"
            id="nameTh"
            v-model.trim="$v.orthoPackage.nameTh.$model"
            :class="{ 'is-invalid': $v.orthoPackage.nameTh.$error }"
          ></b-form-input>
          <template v-slot:description>
            <!-- <span v-if="!$v.orthoPackage.nameTh.isUnique" class="error"
              >ชื่อแพ็กเกจซ้ำ กรุณาเปลี่ยนชื่อแพ็กเกจ</span
            > -->
            <span v-if="!$v.orthoPackage.nameTh.required" class="error"
              >กรุณากรอกชื่อแพ็กเกจ</span
            >
          </template>
        </b-form-group>
        <b-form-group
          label-cols="3"
          label="ชื่อแพ็กเกจ(Eng):"
          label-align="right"
        >
          <b-form-input
            id="nameEn"
            v-model.trim="$v.orthoPackage.nameEn.$model"
            :class="{ 'is-invalid': $v.orthoPackage.nameEn.$error }"
          ></b-form-input>
          <template v-slot:description>
            <!-- <span v-if="!$v.orthoPackage.nameEn.isUnique" class="error"
              >ชื่อแพ็กเกจซ้ำ(Eng) กรุณาเปลี่ยนชื่อแพ็กเกจ</span
            > -->
          </template>
        </b-form-group>
        <b-form-group label-cols="3" label="ราคารวม:" label-align="right">
          <b-input-group>
            <b-form-input v-model="totalPrice" disabled></b-form-input>
            <template v-slot:append>
              <b-input-group-text>บาท</b-input-group-text>
            </template>
          </b-input-group>
          <template v-slot:description>
            <span>ราคารวมจะถูกคำนวนอัติโนมัติจากรายการจัดฟันทั้งหมด</span>
          </template>
        </b-form-group>
        <b-form-group label-cols="3" label="DF:" label-align="right">
          <b-row>
            <b-input-group class="col">
              <b-form-input
                v-model="$v.orthoPackage.df.$model"
                number
                max="100"
                class="required"
                :class="{ 'is-invalid': $v.orthoPackage.df.$error }"
              ></b-form-input>
              <template v-slot:append>
                <b-input-group-text>%</b-input-group-text>
              </template>
            </b-input-group>
            <b-button
              v-b-toggle.dfDetail
              class="col-4 col-lg-3 mr-3"
              variant="secondary"
              size="sm"
              >DF เฉพาะท่าน</b-button
            >
          </b-row>
          <span v-if="!$v.orthoPackage.df.required" class="error"
            >กรุณากรอก DF</span
          >
        </b-form-group>
        <b-row>
          <b-col class="px-2" offset-lg="3">
            <b-collapse id="dfDetail" class="mr-2">
              <b-card>
                <div class="mb-3 text-muted description">
                  กรุณาเลือกแพทย์ที่ต้องการให้ DF เฉพาะท่าน
                  <br />ในกรณีที่ไม่ได้เลือก DF จะคิดเท่ากับ "DF แพ็กเกจ"
                </div>
                <div
                  class="mb-2"
                  v-for="(dfSpec, key, index) in orthoPackage.dfDoctors"
                  :key="dfSpec.id"
                >
                  <b-row v-if="getDoctorListBy(dfSpec.doctorUid, 'uid')">
                    <b-col cols="6" class="pr-0">
                      <div class="text-right pt-1">
                        {{ getDoctorListBy(dfSpec.doctorUid, "uid").fullName }}
                        :
                      </div>
                    </b-col>
                    <b-col>
                      <b-input-group append="%">
                        <b-input-group-prepend is-text>
                          <!-- เพิ่ม data dfActive อีกตัว?? -->
                          <b-form-checkbox
                            switch
                            v-model="dfSpec.dfActive"
                            @input="activateDfSpec(dfSpec)"
                          ></b-form-checkbox>
                        </b-input-group-prepend>
                        <b-form-input
                          class="text-right"
                          type="number"
                          v-model="dfSpec.df"
                          @keyup="validateDfItem(dfSpec, index)"
                          :disabled="!dfSpec.dfActive"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <!-- <b-form-group
                  class="mb-1"
                  v-for="doctor in orthoPackage.dfDoctors"
                  :key="doctor.id"
                  label-cols="6"
                  :label="
                    getDoctorListBy(doctor.doctorUid, 'value').label + ' : '
                  "
                  label-align="right"
                >
                  <b-input-group class="col">
                    <b-form-input
                      type="number"
                      v-model="doctor.df"
                    ></b-form-input>
                    <template v-slot:append>
                      <b-input-group-text>%</b-input-group-text>
                    </template>
                  </b-input-group>
                </b-form-group> -->
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>
        <hr />
        <b-table
          id="PackageItemTable"
          ref="PackageItemTable"
          hover
          small
          sort-icon-left
          head-variant="light"
          :items="orthoPackage.items"
          :fields="fields"
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
          <template v-slot:cell(nameTh)="row">
            <b-form-input
              v-model="row.item.nameTh"
              class="required"
              required
              size="sm"
            ></b-form-input>
          </template>
          <template v-slot:cell(nameEn)="row">
            <b-form-input
              v-model="row.item.nameEn"
              class
              size="sm"
            ></b-form-input>
          </template>
          <template v-slot:cell(price)="row">
            <b-form-input
              number
              v-model="row.item.price"
              class="required"
              required
              size="sm"
            ></b-form-input>
          </template>
          <template v-slot:cell(buttons)="row">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="deletePackageItem(row.item, row.index)"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </template>
          <template v-slot:bottom-row>
            <b-td></b-td>
            <b-td>
              <b-button
                type="button"
                size="sm"
                variant="success"
                @click="addPackageItem"
              >
                <i class="fas fa-plus"></i> เพิ่มรายการ
              </b-button>
            </b-td>
            <b-td class="text-right">ราคารวม:</b-td>
            <b-td>
              <b-form-input
                v-model="totalPrice"
                disabled
                size="sm"
              ></b-form-input>
            </b-td>
            <b-td></b-td>
          </template>
        </b-table>
      </b-form>
    </div>
    <template v-slot:modal-footer>
      <b-button
        variant="danger"
        v-if="orthoPackage.id"
        @click="deleteorthoPackage(orthoPackage)"
        >ลบแพ็กเกจ</b-button
      >
      <b-button type="submit" form="packageTable" variant="primary">{{
        orthoPackage.id ? "แก้ไขแพ็กเกจ" : "สร้างแพ็กเกจ"
      }}</b-button>
    </template>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/Package.scss";
import { required, maxValue, minValue } from "vuelidate/lib/validators";

export default {
  name: "PackageCreateModal",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      orthoPackage: {
        id: null,
        nameTh: "",
        nameEn: "",
        // status: 1,
        // totalPrice: 0, //เพิ่ม data
        items: [
          {
            nameTh: "",
            nameEn: "",
            price: null,
          },
        ],
        df: this.getBranchInfo?.dfPackage || 50, //เพิ่ม data
        dfDoctors: [], //เพิ่ม data
      },
      totalPrice: 0,
      templatePackage: null,
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "nameTh",
          label: "รายการจัดฟัน",
          // sortable: true,
        },
        {
          key: "nameEn",
          label: "รายการจัดฟัน(Eng)",
          // sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
          thStyle: { width: "100px" },
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      isNewPackage: true,
    };
  },
  validations: {
    orthoPackage: {
      nameTh: {
        required,
        // isUnique(name) {
        //   return !this.getOrthoPackageList.filter(
        //     (item) => item.nameTh === name
        //   ).length;
        // },
      },
      nameEn: {
        // isUnique(name) {
        //   if (name === "") return true;
        //   return !this.getOrthoPackageList.filter(
        //     (item) => item.nameEn === name
        //   ).length;
        // },
      },
      df: { required, maxValue: maxValue(100), minValue: minValue(0) },
    },
  },
  computed: {
    ...mapGetters({
      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      getOrthoPackageList: "moduleTreatment/getOrthoPackageList",
      getTemplateOrthoPackageList:
        "moduleTreatment/getTemplateOrthoPackageList",
      getGroupedTemplateOrthoPackageList:
        "moduleTreatment/getGroupedTemplateOrthoPackageList",

      getBranchInfo: "moduleUser/getBranchInfo",
    }),
    modalTitle() {
      return `${
        this.isNewPackage ? "สร้างแพ็กเกจจัดฟัน" : "แก้ไขแพ็กเกจจัดฟัน"
      }`;
    },
  },
  watch: {
    "orthoPackage.items": {
      deep: true,
      handler() {
        this.totalPrice = this.orthoPackage.items.reduce((acc, item) => {
          if (!item.price) {
            return acc;
          } else {
            return acc + parseInt(item.price);
          }
        }, 0);
      },
    },
    templatePackage(val) {
      if (val === -1) return;
      if (val) {
        this.setTemplatePackage(val);
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    ...mapActions({
      fetchOrthoPackageList: "moduleTreatment/fetchOrthoPackageList",
    }),
    init() {},
    show(data) {
      if (data) {
        this.isNewPackage = false;
        this.templatePackage = -1; //! มาแก้ทีหลังด้วย templatePackage ควรเป้น obj
        this.orthoPackage.id = data.id;
        this.orthoPackage.nameTh = data.nameTh;
        this.orthoPackage.nameEn = data.nameEn;
        // this.orthoPackage.status = data.status;
        // this.orthoPackage.totalPrice; // รอ db
        this.orthoPackage.items = data.items;
        this.orthoPackage.df = data.df;
        this.orthoPackage.dfDoctors = data.dfDoctors;
      }
      this.setDfDoctor();
      this.$bvModal.show("packageModal");
    },
    savePackage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const items = this.orthoPackage.items.map((i) => {
        let { id, packageId, ...rest } = i;
        return rest;
      });
      const dfDoctors = this.orthoPackage.dfDoctors
        .filter((i) => i.dfActive)
        .map((i) => {
          let { id, dfActive, branchId, packageId, ...rest } = i;
          return rest;
        });
      let event = { ...this.orthoPackage, items, dfDoctors };

      if (!this.orthoPackage.id) {
        delete event.id;
        this.$store
          .dispatch("moduleTreatment/createOrthoPackage", {
            event,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == 200) {
              this.reloadPackage();
              this.close();
              this.$parent.showDialogToast(
                "success",
                "สร้างแพ็กเกจจัดฟันสำเร็จ"
              );
            } else if (res.status == 400 && res.data === "DUPLICATE-NAME") {
              this.$refs.Dialog.showAlertInfo(
                "ชื่อแพ็กเกจซ้ำ",
                "ไม่สามารถสร้างแพ็กเกจใหม่ได้",
                "warning"
              );
            } else {
              this.$parent.showDialogToast(
                "danger",
                "สร้างแพ็กเกจจัดฟันไม่สำเร็จ"
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขแพ็กเกจจัดฟัน?",
          "คุณต้องการแก้ไขแพ็กเกจจัดฟันนี้"
        ).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("moduleTreatment/updateOrthoPackage", {
                event: event,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              })
              .then((res) => {
                if (res.status == 200) {
                  this.reloadPackage();
                  this.close();
                  this.$parent.showDialogToast(
                    "success",
                    "แก้ไขแพ็กเกจจัดฟันสำเร็จ"
                  );
                } else if (res.status == 400 && res.data === "DUPLICATE-NAME") {
                  this.$refs.Dialog.showAlertInfo(
                    "ชื่อแพ็กเกจซ้ำ",
                    "ไม่สามารถสร้างแพ็กเกจใหม่ได้",
                    "warning"
                  );
                } else {
                  this.$parent.showDialogToast(
                    "danger",
                    "แก้ไขแพ็กเกจจัดฟันไม่สำเร็จ"
                  );
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
      }
    },
    deletePackage(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบแพ็กเกจจัดฟัน?",
        "คุณต้องการลบแพ็กเกจจัดฟันนี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleTreatment/deleteOrthoPackage", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.reloadPackage();
                this.close();
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    reloadPackage() {
      this.fetchOrthoPackageList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
    },
    close() {
      this.$v.$reset();
      this.$bvModal.hide("packageModal");
      this.setDefaultValue();
    },
    addPackageItem() {
      this.orthoPackage.items.push({
        nameTh: "",
        nameEn: "",
        price: null,
      });
    },
    deletePackageItem(eventData, index) {
      // this.$refs.Dialog.showAlertConfirm(
      //   "ลบรายการจัดฟัน ?",
      //   "คุณต้องการลบรายการจัดฟันนี้"
      // ).then((result) => {
      //   if (result.isConfirmed) {
      //     this.orthoPackage.items.splice(index, 1);
      //   }
      // });
      this.orthoPackage.items.splice(index, 1);
    },
    setDefaultValue() {
      this.orthoPackage = Object.assign(
        {},
        {
          id: null,
          nameTh: "",
          nameEn: "",
          // status: 1,
          // totalPrice: 0,
          items: [
            {
              nameTh: "",
              nameEn: "",
              price: 0,
            },
          ],
          df: this.getBranchInfo?.dfPackage || 50,
          dfDoctors: [],
        }
      );
      this.isNewPackage = true;
      this.templatePackage = null;
      this.totalPrice = 0;
    },
    setDfDoctor() {
      this.getDoctorList.forEach((doctor) => {
        let dfDoctor = {
          id: null,
          doctorUid: doctor.uid,
          df: null,
          dfActive: false, // เพิ่มให้สามารถเปิดปิดเฉพาะคนได้
        };
        let index = this.orthoPackage.dfDoctors.findIndex(function (event) {
          return event.doctorUid == doctor.uid;
        });
        if (index < 0) {
          this.orthoPackage.dfDoctors.push(dfDoctor);
        } else {
          this.$set(this.orthoPackage.dfDoctors[index], "dfActive", true); // ให้เปิดใช้ในกรณีที่มี df เฉพาะ
        }
      });
    },
    // setTemplate(orthoPackage) {
    //   this.$store
    //     .dispatch("moduleTreatment/getOrthoPackageById", {
    //       event: orthoPackage.id,
    //       clinicUrl: this.$route.params.clinicUrl,
    //       branchUrl: this.$route.params.branchUrl,
    //     })
    //     .then((res) => {
    //       this.$refs.packageItemModal.show(res.data);
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    setTemplatePackage(val) {
      if (val.id == -1) {
        this.orthoPackage = Object.assign(
          {},
          {
            id: null,
            nameTh: "",
            nameEn: "",
            // status: 1,
            // totalPrice: 0,
            items: [
              {
                nameTh: "",
                nameEn: "",
                price: 0,
              },
            ],
            df: this.getBranchInfo?.dfPackage || 50,
            dfDoctors: [],
          }
        );
        this.setDfDoctor();
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("moduleTreatment/getOrthoPackageById", {
          event: val.id,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then((res) => {
          this.orthoPackage.items = res.data.items;
          this.orthoPackage.nameTh = res.data.nameTh;
          this.orthoPackage.nameEn = res.data.nameEn;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    customLabel(libs) {
      return `${libs.id > 0 ? "ลอกแบบจากแพ็กเกจ -" : ""} ${libs.nameTh}`;
    },
    activateDfSpec(dfSpec) {
      if (!dfSpec.dfActive) {
        dfSpec.df = null;
      }
    },
    validateDfItem(dfSpec) {
      this.setMinMax(dfSpec, "df", 0, 100);
    },

    setMinMax(obj, key, min, max) {
      let num = Math.min(Math.max(obj[key], min), max) || 0;
      obj[key] = Math.round(num * 20) / 20;
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>