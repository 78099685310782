<template>
  <b-modal
    modal-class="component-modal-package"
    id="packageUserModal"
    ref="packageUserModal"
    size="lg"
    :title="title"
    :hide-footer="readOnly || !templatePackage"
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <b-form id="packageTable" @submit.prevent="savePackageUser(packages)">
      <b-form-group
        label-cols="3"
        label="สร้างแพ็กเกจใหม่โดย:"
        label-align="right"
        v-if="!packages.id"
      >
        <multiselect
          class="col p-0"
          v-model="$v.templatePackage.$model"
          :options="getGroupedTemplateOrthoPackageList"
          :multiple="false"
          group-values="libs"
          group-label="category"
          :group-select="false"
          placeholder="สร้างแพ็กเกจใหม่ทั้งหมด หรือลอกแบบจากแพ็กเกจเดิม"
          track-by="id"
          :custom-label="customLabel"
          selectLabel="เลือกแพ็กเกจ"
          selectedLabel="เลือกแล้ว"
          deselectLabel="คลิกเพื่อลบออก"
          openDirection="below"
        >
          <span slot="noResult">ไม่พบรายการ แนะนำเปลี่ยนคำค้นหา</span>
        </multiselect>
      </b-form-group>
      <div v-if="readOnly || templatePackage">
        <b-form-group label-cols="3" label="ชื่อแพ็กเกจ:" label-align="right">
          <b-form-input
            id="nameTh"
            v-model="$v.packages.nameTh.$model"
            :readonly="readOnly"
            :class="{ 'is-invalid': $v.packages.nameTh.$error }"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="3"
          label="ชื่อแพ็กเกจ(Eng):"
          label-align="right"
        >
          <b-form-input
            id="nameEn"
            v-model="packages.nameEn"
            :readonly="readOnly"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label-cols="3" label="ราคารวม:" label-align="right">
          <b-input-group>
            <b-form-input v-model="packages.totalPrice" disabled></b-form-input>
            <template v-slot:append>
              <b-input-group-text>บาท</b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-cols="3"
          label="DF:"
          label-align="right"
          description="ค่า DF กำหนดจากค่าตั้งต้นของสาขา"
        >
          <b-input-group>
            <b-form-input v-model="packages.df" disabled></b-form-input>
            <template v-slot:append>
              <b-input-group-text>%</b-input-group-text>
            </template></b-input-group
          >
        </b-form-group>

        <hr />
        <b-table
          id="PackageItemTable"
          ref="PackageItemTable"
          small
          sort-icon-left
          head-variant="light"
          :items="packages.items"
          :fields="fields"
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
          <template v-slot:cell(nameTh)="row">
            <b-form-input
              v-model="row.item.nameTh"
              class="required"
              required
              size="sm"
              :readonly="readOnly"
            >
            </b-form-input>
          </template>
          <template v-slot:cell(nameEn)="row">
            <b-form-input
              v-model="row.item.nameEn"
              class
              size="sm"
              :readonly="readOnly"
            >
            </b-form-input>
          </template>
          <template v-slot:cell(price)="row">
            <b-form-input
              number
              v-model="row.item.price"
              class="required"
              required
              size="sm"
              :readonly="readOnly"
            >
            </b-form-input>
          </template>
          <template v-slot:cell(buttons)="row">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="deletePackageItem(row.item, row.index)"
              v-if="!readOnly"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </template>
          <template v-slot:bottom-row>
            <b-td></b-td>
            <b-td>
              <b-button
                type="button"
                size="sm"
                variant="success"
                @click="addPackageItem"
                v-if="!readOnly"
              >
                <i class="fas fa-plus"></i> เพิ่มรายการ
              </b-button>
            </b-td>
            <b-td class="text-right">ราคารวม:</b-td>
            <b-td>
              <b-form-input
                v-model="packages.totalPrice"
                disabled
                size="sm"
              ></b-form-input>
            </b-td>
            <b-td></b-td>
          </template>
        </b-table>
      </div>
    </b-form>
    <template v-slot:modal-footer>
      <b-button
        v-if="!readOnly"
        type="submit"
        form="packageTable"
        variant="primary"
      >
        สร้างแพ็กเกจ
      </b-button>
    </template>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/Package.scss";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PackageUserModal",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      packages: {
        id: null,
        nameTh: "",
        nameEn: "",
        status: 1,
        totalPrice: 0, //เพิ่ม data
        items: [
          {
            nameTh: "",
            nameEn: "",
            price: null,
          },
        ],
        df: null,
        dfDoctor: [], //เพิ่ม data
      },
      templatePackage: null,
      fields: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "nameTh",
          label: "รายการ(ไทย)",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "รายการ(Eng)",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
          thStyle: { width: "100px" },
        },
        {
          key: "buttons",
          label: "",
          class: "text-center",
        },
      ],
      patient: null,
      userOrthoPackage: {
        id: null,
        packageId: null,
        uid: null,
      },
      readOnly: false,
    };
  },
  validations: {
    templatePackage: { required },
    packages: {
      nameTh: { required },
    },
  },
  computed: {
    ...mapGetters({
      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      getTemplateOrthoPackageList:
        "moduleTreatment/getTemplateOrthoPackageList",
      getTemplateOrthoPackageBy: "moduleTreatment/getTemplateOrthoPackageBy",
      getGroupedTemplateOrthoPackageList:
        "moduleTreatment/getGroupedTemplateOrthoPackageList",
      getBranchInfo: "moduleUser/getBranchInfo",
    }),
    title() {
      return this.packages.id ? "ข้อมูลแพ็กเกจ" : "สร้างแพ็กเกจเฉพาะบุคคล";
    },
  },
  watch: {
    "packages.items": {
      deep: true,
      handler() {
        this.packages.totalPrice = this.packages.items.reduce((acc, item) => {
          if (!item.price) {
            return acc;
          } else {
            return acc + parseInt(item.price);
          }
        }, 0);
      },
    },

    templatePackage(val) {
      if (val) {
        this.setTemplatePackage(val);
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      fetchTemplateOrthoPackageList:
        "moduleTreatment/fetchTemplateOrthoPackageList",
      fetchOrthoPackageUserList: "moduleTreatment/fetchOrthoPackageUserList",
    }),
    init() {
      // this.fetchTemplateOrthoPackageList({
      //   clinicUrl: this.$route.params.clinicUrl,
      //   branchUrl: this.$route.params.branchUrl,
      // });
    },
    show(patient) {
      this.patient = Object.assign(patient);
      this.$bvModal.show("packageUserModal");
    },
    showSelectedPackage(selectedPackage, df) {
      this.readOnly = true;
      this.packages = { ...selectedPackage };
      this.packages.df = df;
      this.$bvModal.show("packageUserModal");
    },
    savePackageUser(packages) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isLoading = true;

      packages.dfDoctors = packages.dfDoctors.map((i) => {
        delete i.id;
        delete i.branchId;
        delete i.packageId;
        return i;
      });
      packages.items = packages.items.map((i) => {
        delete i.id;
        delete i.packageId;
        return i;
      });
      delete packages.id;
      delete packages.branchId;
      delete packages.creationDt;
      delete packages.modifyDt;
      delete packages.totalPrice;
      delete packages.del;
      this.$store
        .dispatch("moduleTreatment/createOrthoPackageUser", {
          event: packages,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(async (res) => {
          if (res.status == 200) {
            this.userOrthoPackage.packageId = res.data;
            this.userOrthoPackage.uid = this.patient.id;
          }
          await this.createPackageUser(this.userOrthoPackage);
          this.close();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async createPackageUser(userOrthoPackage) {
      delete userOrthoPackage.id;
      await this.$store
        .dispatch("moduleTreatment/assignPatientToOrthoPackage", {
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          package: userOrthoPackage,
        })
        .then(async (res) => {
          if (res.status == 200 && res.data) {
            // await this.fetchOrthoPackageUserList({
            //   clinicUrl: this.$route.params.clinicUrl,
            //   branchUrl: this.$route.params.branchUrl,
            // });
            this.$emit("createUserOrthoPackage");
          }
        })
        .finally(() => {
          // this.$store.dispatch("moduleTreatment/fetchOrthoPackageUserList", {
          //   clinicUrl: this.$route.params.clinicUrl,
          //   branchUrl: this.$route.params.branchUrl,
          // });
        });
    },
    close() {
      this.$v.$reset();
      this.$bvModal.hide("packageUserModal");
      this.setDefaultValue();
    },

    addPackageItem() {
      this.packages.items.push({
        nameTh: "",
        nameEn: "",
        price: null,
      });
    },
    deletePackageItem(eventData, index) {
      this.packages.items.splice(index, 1);
    },
    setDefaultValue() {
      this.packages = Object.assign(
        {},
        {
          id: null,
          nameTh: "",
          nameEn: "",
          status: 1,
          totalPrice: 0,
          items: [
            {
              nameTh: "",
              nameEn: "",
              price: 0,
            },
          ],
          df: this.getBranchInfo?.dfPackage || 50,
          dfDoctor: [],
        }
      );
      this.templatePackage = null;
      this.readOnly = false;
    },
    setTemplatePackage(val) {
      if (val.id == -1) {
        this.packages = Object.assign(
          {},
          {
            id: null,
            nameTh: "",
            nameEn: "",
            status: 1,
            totalPrice: 0,
            items: [
              {
                nameTh: "",
                nameEn: "",
                price: 0,
              },
            ],
            df: this.getBranchInfo?.dfPackage || 50,
            dfDoctor: [],
          }
        );
      } else {
        this.isLoading = true;
        this.$store
          .dispatch("moduleTreatment/getOrthoPackageById", {
            event: val.id,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            this.packages = Object.assign(res.data);
            this.packages.id = null;
            this.packages.df = this.getBranchInfo?.dfPackage || 50; // หรือ ดึงมาจาก branchInfo config (config สาขา)
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    // setViewPackage(val) {
    //   this.$store
    //     .dispatch("moduleTreatment/getOrthoPackageUserById", {
    //       event: val,
    //       clinicUrl: this.$route.params.clinicUrl,
    //       branchUrl: this.$route.params.branchUrl,
    //     })
    //     .then((res) => {
    //       // this.templatePackage = Object.assign(res.data);
    //       this.packages = Object.assign(res.data);
    //     })
    //     .finally(() => {
    //       this.$bvModal.show("packageUserModal");
    //     });
    // },
    customLabel(libs) {
      return `${libs.id > 0 ? "ลอกแบบจากแพ็กเกจ -" : ""} ${libs.nameTh}`;
    },
  },
};
</script>

<style>
/* .required {
  background-color: #ccecff6c;
} */
</style>